<template>
  <b-overlay class="row" :show="loading">
    <div class="col-12 g">
      <div class="step1" v-if="step == 1">
        <div class="card card-body">
          <div class="alert alert-danger g" v-if="students.length == 0">
            <h5>برجاء استيراد الطلاب اولاً.</h5>
          </div>
          <div class="row" v-if="students.length > 0">
            <div class="col-12">
              <p>
                اختر الفصول المراد اعداد اللجان لها
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-secondary"
                  @click="selectAllClasses()"
                >
                  <i class="fa fa-check-square"></i> اختيار الكل</a
                >
                &nbsp;
                <a
                  href="javascript:;"
                  class="btn btn-sm text-danger"
                  v-if="selected_classrooms.length > 0"
                  @click="unSelectAllClasses()"
                >
                  <i class="fa fa-times"></i> الغاء تحديد الكل</a
                >
              </p>
            </div>
            <div
              class="col-12 col-lg-4 g"
              v-for="classa in classes"
              :key="classa.classname"
            >
              <div class="card card-body border">
                <h5>
                  {{ classname(classa.classname) }}
                </h5>
                <span class="text-muted">..............................</span>
                <div class="row">
                  <div
                    class="col-12 col-lg-6"
                    v-for="classroom in classa.classrooms"
                    :key="classa.classname + ',' + classroom"
                  >
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="selected_classrooms"
                          :value="classa.classname + ',' + classroom"
                        />
                        {{ classroom }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 text-center"
              v-if="selected_classrooms.length > 0"
            >
                <h3 for="">رقم الجلوس يبدأ من</h3>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="start_serial_all_check">
                    تعيين رقم الجلوس لكل الصفوف.
                  </label>
                </div>
              <br><br>
              <div class="col-12 col-lg-7 c" v-if="!start_serial_all_check">
                <div class="row">
                <template v-for="c in classes">
                <div class="form-group col-12 col-lg-6 c" v-if="ssc.includes(c.classname)" :key="c.classname">
                <h5 for="">{{ classname(c.classname) }} يبدأ بـ</h5>
                <input
                  type="number"
                  class="form-control"
                  v-model="start_serial[c.classname]"
                />
              </div></template>
              </div>
              </div>
              
              <div class="col-12 col-lg-5 c" v-if="start_serial_all_check">
                <h5>الكل يبدأ بـ</h5>
                <input
                  type="number"
                  class="form-control"
                  v-model="start_serial_all"
                />
              </div>
              <br />
              <button class="btn btn-relief-primary btn-lg" @click="filterStudents(); step = 2">
                الخطوة التالية
                <i class="fa fa-arrow-left"></i>
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Step 2 -->
      <div class="col-12 c step3" v-if="step == 2">
        <div class="card card-body">
          <div class="row">
            <div class="col-12">
              <h5>اختر طريقة التوزيع</h5>
              <div class="form-check g" style="padding-right: 50px">
                <h6 class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="sortStudents"
                    :value="true"
                  />
                  ترتيب الطلاب أبجدياً حسب الصف
                </h6>
              </div>
            </div>
            <div
              :class="
                'col-12 col-lg-4 g text-center hover ' +
                (orderedType == 3 ? 'selectedCard' : '')
              "
            >
              <div
                class="card card-body border"
                @click="
                  orderedType = 3;
                  step = 3;
                "
              >
                <h5>
                  <img
                    :src="require('@/assets/images/classroom3.png')"
                    style="width: 60px"
                    alt=""
                  />
                  توزيع ديناميكي
                </h5>
              </div>
            </div>
            <div
              :class="
                'col-12 col-lg-4 g text-center hover ' +
                (orderedType == 1 ? 'selectedCard' : '')
              "
            >
              <div
                class="card card-body border"
                @click="
                  orderedType = 1;
                  selectedStudents();
                  orderStudents();
                  createCommittees();
                  step = 5;
                  save();
                "
              >
                <h5>
                  <img
                    :src="require('@/assets/images/classroom-1.png')"
                    style="width: 60px"
                  />
                  كل فصل في لجنة
                </h5>
              </div>
            </div>
            <div
              :class="
                'col-12 col-lg-4 g text-center hover ' +
                (orderedType == 2 ? 'selectedCard' : '')
              "
            >
              <div
                class="card card-body border"
                @click="
                  orderedType = 2;
                  step = 3;
                "
              >
                <h5>
                  <img
                    :src="require('@/assets/images/classroom-2.png')"
                    style="width: 60px"
                    alt=""
                  />
                  توزيع تسلسلي (توزيع كل صف في عامود)
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Step 2 -->
      <div class="col-12 col-lg-6 c step2" v-if="step == 3">
        <div class="card card-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <h5 for="">
                  عدد اللجان المطلوبة
                  <a
                    href="javascript:;"
                    class="btn btn-sm"
                    @click="committeesLengthType = !committeesLengthType"
                  >
                    <u><i class="fa fa-keyboard-o"></i> اكتب الرقم يدوياً</u></a
                  >
                </h5>
                <input
                  type="number"
                  placeholder="اكتب العدد هنا..."
                  class="form-control form-control-lg"
                  v-model="committeesLength"
                  v-if="!committeesLengthType"
                />
                <select
                  class="form-control"
                  v-model="committeesLength"
                  v-if="committeesLengthType"
                >
                  <option :value="0">-- اختر --</option>
                  <template v-for="i in 100">
                    <option :value="i" :key="i">
                      {{ i }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="col-12 text-center" v-if="committeesLength > 0">
              <button
                class="btn btn-relief-primary btn-lg"
                @click="
                  step = 4;
                  createCommittees();
                "
              >
                الخطوة التالية
                <i class="fa fa-arrow-left"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Step 3 -->
      <div class="col-12 col-lg-12 c step4" v-if="step == 4">
        <div class="card card-body">
          <div
            class="alert border g"
            v-html="totalStats()"
            v-if="orderedType == 3"
          ></div>
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <th>اللجنة</th>
                <th>مقر اللجنة</th>
                <th
                  :class="
                    totalWritedStudents() == students.length
                      ? ''
                      : 'text-danger'
                  "
                >
                  اقصى عدد للطلاب
                  <br />
                  (الاجمالي: {{ totalWritedStudents() }} من
                  {{ students.length }})
                </th>
                <th v-if="orderedType == 2">عدد القاطرات</th>
                <th v-if="orderedType == 2">عدد الطلاب في كل قاطرة</th>
                <th v-if="orderedType == 3">عدد الطلاب من كل صف</th>
              </thead>
              <tbody>
                <tr v-for="committee in committees" :key="committee.title">
                  <td>
                    {{ committee.title }}
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="اكتب هنا..."
                      v-model="committee.address"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="اكتب هنا..."
                      v-model="committee.count"
                      @keyup="
                        committee.students_per_row = (
                          committee.count / committee.rows
                        ).toFixed()
                      "
                    />
                  </td>
                  <td v-if="orderedType == 2">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="اكتب هنا..."
                      v-model="committee.rows"
                      @keyup="
                        if (committee.rows > classes.length) {
                          showMessage(
                            'يجب الا يكون عدد القاطرات اكثر من عددد الصفوف'
                          );
                        } else {
                          committee.students_per_row = (
                            committee.count / committee.rows
                          ).toFixed();
                        }
                      "
                    />
                  </td>
                  <td v-if="orderedType == 2">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="اكتب هنا..."
                      v-model="committee.students_per_row"
                    />
                  </td>
                  <td
                    v-if="orderedType == 3"
                    style="padding: 0px"
                    :class="
                      countSelectedClassesCount(committee) > committee.count
                        ? 'bg-danger text-white2'
                        : ''
                    "
                  >
                    <small class="text-primary"
                      >{{ committee.title }} -
                      <strong
                        >({{ countSelectedClassesCount(committee) }} من
                        {{ committee.count }})</strong
                      ></small
                    >
                    <div
                      class="input-group mb-3"
                      style="border-radius: 0px !important"
                      v-for="cl in classes"
                      :key="cl.classname"
                    >
                      <div
                        class="input-group-prepend"
                        style="border-radius: 0px !important"
                      >
                        <span
                          class="input-group-text"
                          style="border-radius: 0px !important"
                          id="basic-addon1"
                          >{{ classname(cl.classname) }}</span
                        >
                      </div>
                      <input
                        type="number"
                        v-model="committee.classes[cl.classname]"
                        style="border-radius: 0px !important"
                        class="form-control"
                        placeholder="اكتب هنا..."
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            <button
              class="btn btn-success"
              @click="
                selectedStudents();
                orderStudents();
                step = 5;
              "
            >
              توزيع الآن <i class="fa fa-check"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- End Step 4 -->
      <!-- End Step 5 -->
      <div class="col-12 col-lg-10 c step5" v-if="step == 5">
        <div class="card card-body">
          <p>استعراض اللجان 
            &nbsp;
            &nbsp;

                        <b-dropdown
                          dropleft
                          style="border-radius: 0px"
                          size="sm"
                          text="طباعة كشف الطلاب"
                        >
                        <p class="g">اختر الصف:</p>
                          <b-dropdown-item v-for="cl in classes" :key="cl.classname" @click="printClass(cl.classname)">
                            {{ cl.classname }}
                          </b-dropdown-item>
                        </b-dropdown>
            &nbsp;
            <button class="btn btn-info btn-sm" style="border-radius: 0px" v-b-modal.modal-2 @click="teachersfetch()">
              <i class="fa fa-users"></i>
              توزيع الملاحظين على اللجان
            </button>
          </p>
          <app-collapse>
            <app-collapse-item
              v-for="c in committees"
              :key="c.title"
              :title="
                c.title +
                (c.classname
                  ? ` --- [${classname(c.classname)} - ${c.classroom}] (${
                      c.students.length
                    } طالب)`
                  : '')
              "
            >
              <div class="col-12 table-responsive">
                <div class="row">
                  <div class="col-12 col-lg-4 g text-center hover">
                    <div class="card card-body border" @click="print1(c)">
                      <span
                        ><i class="fa fa-file"></i> كشف توزيع الطلاب <br />
                        لهذه اللجنة</span
                      >
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 g text-center hover">
                    <div class="card card-body border" @click="print2(c)">
                      <span
                        ><i class="fa fa-file"></i> كشف استلام أوراق <br />
                        اجابات الطلاب</span
                      >
                    </div>
                    
                    <div class="row">
                      <div class="col-6">
                        <button
                          style="border-radius: 0px"
                          :class="
                            'btn btn-block btn-sm ' +
                            (size2 == 'all' ? 'btn-outline-success' : '')
                          "
                          @click="size2 = 'all'; "
                        >
                         طباعة الكل
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          style="border-radius: 0px"
                          :class="
                            'btn btn-block btn-sm ' +
                            (size2 == 'split' ? 'btn-outline-success' : '')
                          "
                          @click="size2 = 'split';"
                        >
                         كل صف في ورقة
                        </button>
                      </div>
                      <div class="col-12" v-if="size2 == 'split'">
                        <div class="form-group">
                          <label for="">اختر الصف المراد طباعته</label>
                          <select class="form-control" v-model="selectedclass">
                            <option :value="c.classname" v-for="(c, i) in classes" :key="i">{{ classname(c.classname) }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 g text-center hover">
                    <div class="card card-body border" @click="print3(c)">
                      <span
                        ><i class="fa fa-id-card-o"></i> طباعة كروت طلاب<br />(ملصقات
                        الطلاب)</span
                      >
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button
                          style="border-radius: 0px"
                          :class="
                            'btn btn-block btn-sm ' +
                            (size == 'col-4' && size4 == '1' ? 'btn-outline-success' : '')
                          "
                          @click="size = 'col-4'; size4='1'"
                        >
                          3 في كل صف/7 في الصفحة
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          style="border-radius: 0px"
                          :class="
                            'btn btn-block btn-sm ' +
                            (size == 'col-6' && size4 == '1' ? 'btn-outline-success' : '')
                          "
                          @click="size = 'col-6'; size4='1'"
                        >
                          2 في كل صف/7 في الصفحة
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          style="border-radius: 0px"
                          :class="
                            'btn btn-block btn-sm ' +
                            (size == 'col-4' && size4 == '6' ? 'btn-outline-success' : '')
                          "
                          @click="size = 'col-4'; size4='6'"
                        >
                          3 في كل صف/6 في الصفحة
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          style="border-radius: 0px"
                          :class="
                            'btn btn-block btn-sm ' +
                            (size == 'col-6' && size4 == '6' ? 'btn-outline-success' : '')
                          "
                          @click="size = 'col-6'; size4='6'"
                        >
                          2 في كل صف/6 في الصفحة
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 g text-center hover">
                    <div
                      class="card card-body border"
                      @click="current = c"
                      v-b-modal.modal-1
                    >
                      <span
                        ><i class="fa fa-paper-plane"></i> ارسال البيانات
                        لأولياء الامور
                        <br />
                        (واتساب و SMS)</span
                      >
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 g text-center hover">
                    <div class="card card-body border" @click="print4(c)">
                      <span
                        ><i class="fa fa-print"></i> طباعة بانر اللجنة
                        <br />
                        (ملصق ببيانات اللجنة)</span
                      >
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 g text-center hover">
                    <div class="card card-body">
                      <div class="row">
                        <div class="col-12 col-lg-6 g">
                          <button
                            class="btn btn-sm btn-secondary"
                            style="
                              border-radius: 0px;
                              border-left: solid 1px #000 !important;
                            "
                            @click="changeAttr(c, 'title')"
                          >
                            <i class="fa fa-edit"></i>
                            اسم اللجنة
                          </button>
                        </div>
                        <div class="col-12 col-lg-6 g">
                          <button
                            class="btn btn-sm btn-secondary"
                            style="border-radius: 0px"
                            @click="changeAttr(c, 'address')"
                          >
                            <i class="fa fa-edit"></i>
                            مقر اللجنة
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table class="table table-hover" id="currentTable">
                  <thead>
                    <th>الإسم</th>
                    <th>رقم الهوية</th>
                    <th v-if="orderedType == 2">القاطرة</th>
                    <th>الصف</th>
                    <th>الفصل</th>
                  </thead>
                  <tbody>
                    <tr v-for="(student, i) in c.students" :key="student.number" :id="'f' + student.number">
                      <td>
                        <b-dropdown
                        title="نقل الى"
                          dropleft
                          style="border-radius: 0px"
                          size="sm"
                        >
                          <div class="form-group">
                            <label for="">نقل إلى</label>
                            <select
                              class="form-control"
                              v-model="movetott"
                              @change="moveStudent(student._id)"
                            >
                              <template v-for="com in committees">
                                <option :key="com.title">
                                  {{ com.title }}
                                </option>
                              </template>
                            </select>
                          </div>
                        </b-dropdown>
                        <button class="btn btn-outline-danger btn-sm" title="نقل للاعلى" @click="moveUp(c.title, i, student.number)" style="border-radius: 0px" v-if="i != 0">
                          <i class="fa fa-arrow-up"></i>
                        </button>
                        <button class="btn btn-outline-success btn-sm" title="نقل للاسفل" @click="moveDown(c.title, i, student.number)" style="border-radius: 0px" v-if="i != c.students.length - 1">
                          <i class="fa fa-arrow-down"></i>
                        </button>
                        {{ student.name }}
                      </td>
                      <td>
                        {{ student.number }}
                      </td>
                      <td v-if="orderedType == 2">
                        {{ student.row }}
                      </td>
                      <td>
                        {{ classname(student.classname) }}
                      </td>
                      <td>
                        {{ student.classroom }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </app-collapse-item>
          </app-collapse>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      title="توزيع الملاحظين"
      size="lg"
      hide-footer
    >
    <div class="col-12 table-responsive" id="cccsssxsx">
      <table class="table table-hover table-bordered table-sm">
      <tbody>
        <tr>
          <td>
            اليوم
          </td>
          <td>
            <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-model="teachersC['day']">
          </td>
          <td>
            التاريخ
          </td>
          <td>
            <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-model="teachersC['date']">
          </td>
        </tr>
        <tr>
          <td>
            المادة
          </td>
          <td>
            <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-model="teachersC['subject']">
          </td>
          <td>
            الفترة
          </td>
          <td>
            <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-model="teachersC['fatra']">
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table table-hover table-bordered table-sm">
      <thead>
        <th>
          اللجنة
        </th>
        <th>
          اسم الملاحظ
        </th>
      </thead>
      <tbody>
        <tr v-for="(committee, i) in committees" :key="i">
          <td>
            {{ committee.title }}
          </td>
          <td>
            <div class="row">
              <div class="col-9">
                <select style="border: none; width: 100%; height: 20px;" v-if="!manuals[committee.title]" v-model="teachersC[committee.title]" @change="refreshTeachers()">
                  <option value="select">-- اختر --</option>
                  <template v-for="teacher in teachers">
                    <option :key="teacher.name" :value="teacher.name" v-if="!dones.includes(teacher.name) || teachersC[committee.title] == teacher.name">{{ teacher.name }}</option>
                  </template>
              </select>
              <textarea type="text" placeholder="اكتب كل ملاحظ في سطر منفصل..." @change="refreshTeachers()" style="border: none; width: 100%;" v-if="manuals[committee.title]" v-model="teachersC[committee.title]"></textarea>
              </div>
              <div class="col-3">
                <button class="btn btn-sm" @click="manuals[committee.title] = !manuals[committee.title]; teachersC[committee.title] = teachersC[committee.title].replace('select', ''); refreshTeachers()">
                  <i class="fa fa-edit"></i> يدوياً
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    
    <table class="table table-hover table-bordered table-sm">
      <tbody>
        <tr>
          <td>
            الاحتياط الاول
          </td>
          <td>
            <div class="row">
              <div class="col-9">
                <select style="border: none; width: 100%; height: 20px;" v-if="!manuals['e1']" v-model="teachersC['e1']" @change="refreshTeachers()">
                  <option value="select">-- اختر --</option>
                  <template v-for="teacher in teachers">
                    <option :key="teacher.name" :value="teacher.name" v-if="!dones.includes(teacher.name) || teachersC['e1'] == teacher.name">{{ teacher.name }}</option>
                  </template>
              </select>
              <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-if="manuals['e1']" v-model="teachersC['e1']">
              </div>
              <div class="col-3">
                <button class="btn btn-sm" @click="manuals['e1'] = !manuals['e1']; teachersC['e1'] = teachersC['e1'].replace('select', ''); refreshTeachers()">
                  <i class="fa fa-edit"></i> 
                </button>
              </div>
            </div>
          </td>
          <td>
            الاحتياط الثاني
          </td>
          <td>
            <div class="row">
              <div class="col-9">
                <select style="border: none; width: 100%; height: 20px;" v-if="!manuals['e2']" v-model="teachersC['e2']" @change="refreshTeachers()">
                  <option value="select">-- اختر --</option>
                  <template v-for="teacher in teachers">
                    <option :key="teacher.name" :value="teacher.name" v-if="!dones.includes(teacher.name) || teachersC['e2'] == teacher.name">{{ teacher.name }}</option>
                  </template>
              </select>
              <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-if="manuals['e2']" v-model="teachersC['e2']">
              </div>
              <div class="col-3">
                <button class="btn btn-sm" @click="manuals['e2'] = !manuals['e2']; teachersC['e2'] = teachersC['e2'].replace('select', ''); refreshTeachers()">
                  <i class="fa fa-edit"></i> 
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            المشرف الاول
          </td>
          <td>
            <div class="row">
              <div class="col-9">
                <select style="border: none; width: 100%; height: 20px;" v-if="!manuals['m1']" v-model="teachersC['m1']" @change="refreshTeachers()">
                  <option value="select">-- اختر --</option>
                  <template v-for="teacher in teachers">
                    <option :key="teacher.name" :value="teacher.name" v-if="!dones.includes(teacher.name) || teachersC['m1'] == teacher.name">{{ teacher.name }}</option>
                  </template>
              </select>
              <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-if="manuals['m1']" v-model="teachersC['m1']">
              </div>
              <div class="col-3">
                <button class="btn btn-sm" @click="manuals['m1'] = !manuals['m1']; teachersC['m1'] = teachersC['m1'].replace('select', ''); refreshTeachers()">
                  <i class="fa fa-edit"></i> 
                </button>
              </div>
            </div>
          </td>
          <td>
            المشرف الثاني
          </td>
          <td>
            <div class="row">
              <div class="col-9">
                <select style="border: none; width: 100%; height: 20px;" v-if="!manuals['m2']" v-model="teachersC['m2']" @change="refreshTeachers()">
                  <option value="select">-- اختر --</option>
                  <template v-for="teacher in teachers">
                    <option :key="teacher.name" :value="teacher.name" v-if="!dones.includes(teacher.name) || teachersC['m2'] == teacher.name">{{ teacher.name }}</option>
                  </template>
              </select>
              <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-if="manuals['m2']" v-model="teachersC['m2']">
              </div>
              <div class="col-3">
                <button class="btn btn-sm" @click="manuals['m2'] = !manuals['m2']; teachersC['m2'] = teachersC['m2'].replace('select', ''); refreshTeachers()">
                  <i class="fa fa-edit"></i> 
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            المشرف الثالث
          </td>
          <td>
            <div class="row">
              <div class="col-9">
                <select style="border: none; width: 100%; height: 20px;" v-if="!manuals['m3']" v-model="teachersC['m3']" @change="refreshTeachers()">
                  <option value="select">-- اختر --</option>
                  <template v-for="teacher in teachers">
                    <option :key="teacher.name" :value="teacher.name" v-if="!dones.includes(teacher.name) || teachersC['m3'] == teacher.name">{{ teacher.name }}</option>
                  </template>
              </select>
              <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-if="manuals['m3']" v-model="teachersC['m3']">
              </div>
              <div class="col-3">
                <button class="btn btn-sm" @click="manuals['m3'] = !manuals['m3']; teachersC['m3'] = teachersC['m3'].replace('select', ''); refreshTeachers()">
                  <i class="fa fa-edit"></i> 
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            مراقب اللجان
          </td>
          <td>
            <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-model="teachersC['mor']">
          </td>
          <td>
            مدير المدرسة
          </td>
          <td>
            <input type="text" placeholder="اكتب هنا..." @change="refreshTeachers()" style="border: none; width: 100%; height: 20px;" v-model="teachersC['mod']">
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-group">
      <label for="">ملاحظات</label>
      <textarea class="form-control" v-model="teachersC['notes']" rows="3"></textarea>
    </div>
    <div class="col-12 text-center g">
      <button class="btn btn-primary" @click="printc()">
        عرض الكشف
      </button>
    </div>
    </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="ارسال البيانات لاولياء الامور"
      size="lg"
      hide-footer
    >
      <h5 class="g">ارسال بيانات لجنة: "{{ current.title }}"</h5>
      <div class="g">
        <div class="form-group">
          <label for="">الرسالة</label>
          <textarea
            class="form-control"
            id="message"
            rows="6"
            v-model="message"
          ></textarea>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 g">
            <button class="btn btn-relief-success btn-block" id="brmja-send">
              <i class="fa fa-whatsapp"></i>
              ارسال عبر اداة الواتساب (في المتصفح)
            </button>
          </div>
          <div class="col-12 col-lg-6 g">
            <button class="btn btn-relief-primary btn-block" @click="sendSMS()">
              <i class="fa fa-phone"></i>
              ارسال SMS او واتساب في الخلفية
            </button>
          </div>
          <div class="col-12 g" id="brmja-check-not-installed">
            <div class="alert alert-info text-center g">
              لارسال عبر الواتساب في المتصفح، يجب تنزيل اداة الارسال الخاصة بنا.
              <br />
              <a
                href="https://chrome.google.com/webstore/detail/%D8%AE%D8%AF%D9%85%D8%A7%D8%AA-%D8%A7%D8%B6%D8%A7%D9%81%D9%8A%D8%A9-%D8%A8%D8%B1%D9%85%D8%AC%D8%A9-%D8%AF%D9%88%D8%AA-%D9%83%D9%88/klbkaeaebhjkhmhilmbkfdcjdlinjpco?hl=ar"
                target="_blank"
                >يمكنك تنزيلها من هنا</a
              >
            </div>
          </div>
        </div>
        <br />
        <div
          class="col-12 g text-center bg-light-success text-white"
          v-if="loading"
        >
          <h1>
            <img :src="require('@/assets/images/loading.svg')" alt="" />
            <br /><br />
            جاري الارسال...
          </h1>
        </div>
        <div class="col-12 table-responsive">
          <table
            class="table table-hover table-bordered"
            id="brmja-messages-table"
          >
            <thead>
              <th>الجوال</th>
              <th>الطالب</th>
              <th>الرسالة</th>
            </thead>
            <tbody>
              <tr v-for="student in current.students" :key="student.number">
                <td>
                  {{ student.phone }}
                </td>
                <td>
                  {{ student.name }}
                </td>
                <td>
                  <textarea class="form-control" readonly>{{
                    message
                      .replace("{name}", student.name)
                      .replace("{class}", classname(student.classname))
                      .replace("{classroom}", student.classroom)
                      .replace("{number}", student.number)
                      .replace("{id}", student.serial)
                      .replace("{lag}", current.title)
                      .replace("{loc}", current.address)
                  }}</textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
var info= localStorage.getItem("info")
        ? JSON.parse(localStorage.getItem("info"))
        : {
            edara: "",
            school: "",
            admin: "",
            boss: "",
            maktab: "",
          };
import {
  BOverlay,
  BModal,
  VBModal,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BOverlay,
    AppCollapse,
    AppCollapseItem,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      teachersC: {},
      manuals: {},
      info: localStorage.getItem("info")
        ? JSON.parse(localStorage.getItem("info"))
        : {
            edara: "",
            school: "",
            admin: "",
            boss: "",
            maktab: "",
          },
      selectedclass: null,
      size2: "all",
      loading: true,
      step: 0,
      // Step 1
      students: [],
      classes: [],
      classesObject: {},
      selected_classrooms: [],
      size4: "1",
      // Step 2
      committeesLength: 0,
      committeesLengthType: true,
      // Step 3
      orderedType: 0,
      // Step 4
      start_serial: {},
      start_serial_all: 1,
      start_serial_all_check: false,
      committees: [],
      //
      classnames: JSON.parse(localStorage.getItem("classnames")),
      sortStudents: false,
      size: "col-4",
      movetott: "",
      current: {},
      loading: false,
      saved: false,
      ssc: [],
      teachers: [],
      dones: [],
      message:
        "الاسم: {name}\nالصف: {class}\nالفصل: {classroom}\nرقم الهوية: {number}\nرقم الجلوس: {id}\nاللجنة: {lag}\nمقر اللجنة: {loc}",
    };
  },
  created() {
    var g = this;
    var last_f = null;
    $.post(api + "/user/teachers/index", {
      jwt: user.jwt,
    })
    .then(function (r) {
      g.teachers = JSON.parse(r);
    })
    .catch(function () {
      alert("حدث خطأ");
    });
    setInterval(() => {
      if(last_f != this.selected_classrooms){
        g.ssc = [];
        g.selected_classrooms.forEach(function(a){
          g.ssc.push(a.split(",")[0])
        })
      }
    }, 100);
    $.post(api + "/committees/students", {
      jwt: user.jwt,
    })
      .then(function (r) {
        var ss = 1;
        if (r.status == 100) {
          g.students = r.response;
          //
          r.response.forEach((student) => {
            if (!g.classesObject[student.classname]) {
              var classrooms = [];
              r.response.forEach((student2) => {
                if (
                  student2.classname == student.classname &&
                  !classrooms.includes(student2.classroom)
                ) {
                  classrooms.push(student2.classroom);
                }
              });
              classrooms.sort(function(a, b){return a-b});
              g.classesObject[student.classname] = classrooms;
              g.classes.push({
                classname: student.classname,
                classrooms: classrooms,
              });
              g.start_serial[student.classname] = ss;
              ss = ss + 1000
            }
          });
          //
          g.loading = false;
          g.step = 1;
          if (window.location.hash) {
            g.loading = true;
            g.step = 5;
            $.post(api + "/committees/one", {
              jwt: user.jwt,
              id: window.location.hash.split("#")[1],
            })
              .then(function (a) {
                g.orderedType = a.response.type;
                g.committees = JSON.parse(a.response.arr);
                g.loading = false;
              })
              .catch(function () {
                alert("حدث خطأ");
              });
          }
        } else {
          alert("حدث خطأ2");
        }
      })
      .catch(function () {
        alert("حدث خطأ");
      });
  },
  methods: {
    classname(name) {
      var a = {};
      a.classname = name.replace("", "");
      var classnames = this.classnames;
      for (const property in classnames) {
        a.classname = a.classname.replace(
          classnames[property].number,
          classnames[property].name
        );
      }
      a.classname = a.classname.replace("0725", "الأول المتوسط");
      a.classname = a.classname.replace("0825", "الثاني المتوسط");
      a.classname = a.classname.replace("0925", "الثالث المتوسط");
      a.classname = a.classname.replace("0125", "الأول ابتدائي");
      a.classname = a.classname.replace("0225", "الثاني ابتدائي");
      a.classname = a.classname.replace("0325", "الثالث ابتدائي");
      a.classname = a.classname.replace("0430", "الرابع ابتدائي");
      a.classname = a.classname.replace("0530", "الخامس ابتدائي");
      a.classname = a.classname.replace("0630", "السادس ابتدائي");
      a.classname = a.classname.replace("1314", "أول ثانوي");
      a.classname = a.classname.replace("1416", "ثاني ثانوي");
      a.classname = a.classname.replace("1516", "ثالث ثانوي");
      //
      a.classname = a.classname.replace("0108", "الأول ابتدائي");
      a.classname = a.classname.replace("0208", "الثاني ابتدائي");
      a.classname = a.classname.replace("0308", "الثالث ابتدائي");
      a.classname = a.classname.replace("0435", "الرابع ابتدائي");
      a.classname = a.classname.replace("0535", "الخامس ابتدائي");
      a.classname = a.classname.replace("0636", "السادس ابتدائي");
      return a.classname;
    },
    selectAllClasses() {
      var g = this,
        a = [];
      this.classes.forEach(function (cl) {
        if (!a.includes(cl.classname)) {
          cl.classrooms.forEach((c) => {
            a.push(cl.classname + "," + c);
          });
        }
      });
      g.selected_classrooms = a;
    },
    unSelectAllClasses() {
      this.selected_classrooms = [];
    },
    createCommittees() {
      var g = this,
        i = 0;
      if (g.orderedType == 1) {
        this.classes.forEach(function (c) {
          c.classrooms.forEach(function (cl) {
            i = i + 1;
            var count = 0,
              s = [];
            g.students.forEach(function (st) {
              if (st.classname == c.classname && st.classroom == cl) {
                count = count + 1;
                st.serial = new Number(g.start_serial_all_check ? g.start_serial_all : g.start_serial[c.classname]);
                g.start_serial[c.classname] = new Number(g.start_serial[c.classname]) + 1;
                g.start_serial_all = new Number(g.start_serial_all) + 1;
                s.push(st);
              }
            });
            g.committees.push({
              title: "اللجنة رقم " + i,
              address: "",
              count: count,
              classname: c.classname,
              classroom: cl,
              students: s,
            });
          });
        });
      }
      if (g.orderedType == 2) {
        for (var i = 1; i <= this.committeesLength; i++) {
          g.committees.push({
            title: "اللجنة رقم " + i,
            address: "",
            count: 1,
            students: [],
            rows: 1,
            students_per_row: 3,
          });
        }
      }
      if (g.orderedType == 3) {
        for (var i = 1; i <= this.committeesLength; i++) {
          var classesc = {};
          g.classes.forEach(function (a) {
            classesc[a.classname] = 0;
          });
          g.committees.push({
            title: "اللجنة رقم " + i,
            address: "",
            count: 1,
            students: [],
            classes: classesc,
          });
        }
      }
    },
    filterStudents(){
      var g = this;
      g.students = g.students.filter((x)=>{
        if(g.selected_classrooms.includes(x.classname + ',' + x.classroom)){
          return true;
        }
        return false;
      })
    },
    selectedStudents() {
      var g = this,
        c = this.students;
      this.students = [];
      c.forEach(function (student) {
        if (
          g.selected_classrooms.includes(
            student.classname + "," + student.classroom
          )
        ) {
          g.students.push(student);
        }
      });
    },
    orderStudents() {
      var g = this;
      if (g.sortStudents) {
        var l1 = [],
          l2 = [];
        this.students.forEach(function (a) {
          if (!l1.includes(a.classname)) {
            l1.push(a.classname);
          }
        });
        l1.forEach(function (a) {
          var l = [];
          g.students.forEach(function (s) {
            if (s.classname == a) {
              l.push(s);
            }
          });
          if (g.sortStudents) {
            l.sort((a, b) => (a.name > b.name ? 1 : -1));
          }
          l.forEach(function (c) {
            l2.push(c);
          });
        });
        this.students = l2;
      } else {
        var l1 = [],
          l2 = [],
          l3 = [];
        this.students.forEach(function (a) {
          if (!l1.includes(a.classname + "---" + a.classroom)) {
            l1.push(a.classname + "---" + a.classroom);
            l3.push({
              classname: a.classname,
              classroom: a.classroom,
            });
          }
        });
        l3.forEach(function (a) {
          var l = [];
          g.students.forEach(function (s) {
            if (s.classname == a.classname && s.classroom == a.classroom) {
              l.push(s);
            }
          });
          //l.sort((x, b) => (x.name > b.name ? 1 : -1));
          l.sort(function (x, b) {
            return x.name.localeCompare(b.name) || b.classroom - x.classroom;
          });
          l.forEach(function (c) {
            l2.push(c);
          });
        });
        this.students = l2;
      }

      var sts = JSON.parse(JSON.stringify(g.students)),
        classeslist = [],
        doneStudents = [];
      // type 2

      if (this.orderedType == 2) {
        this.classes.forEach(function (cla) {
          classeslist.push(cla.classname);
        });
        this.committees.forEach((c) => {
          var classeslist2 = JSON.parse(JSON.stringify(classeslist)),
            blockedClasses = [];
          for (var row = 1; row <= c.rows; row++) {
            var st_1 = 0;
            sts.forEach(function (student) {
              classeslist2.forEach(function (cla) {
                if (student.classname == cla && !blockedClasses.includes(cla)) {
                  if (
                    st_1 < c.students_per_row &&
                    !doneStudents.includes(student.number) &&
                    c.count > c.students.length
                  ) {
                    st_1 = st_1 + 1;
                    doneStudents.push(student.number);
                    student.row = row;
                    student.serial = new Number(g.start_serial_all_check ? g.start_serial_all : g.start_serial[student.classname]);
                g.start_serial[student.classname] = new Number(g.start_serial[student.classname]) + 1;
                g.start_serial_all = new Number(g.start_serial_all) + 1;
                    c.students.push(student);
                    if (st_1 == c.students_per_row) {
                      blockedClasses.push(cla);
                    }
                  }
                }
              });
            });
          }
        });
      }
      // type 3
      if (this.orderedType == 3) {
        var sts = JSON.parse(JSON.stringify(g.students));
        this.committees.forEach((c) => {
          for (const cl in c.classes) {
            var stus = [];
            function compare( a, b ) {
  if ( new Number(a.classroom) < new Number(b.classroom) ){
    return -1;
  }
  if ( new Number(a.classroom) > new Number(b.classroom) ){
    return 1;
  }
  return 0;
}
if(!g.sortStudents){
sts.sort( compare );
}
            sts.forEach(function (student) {
              if (
                student.classname == cl &&
                !doneStudents.includes(student.number) &&
                c.classes[cl] > stus.length
              ) {
                doneStudents.push(student.number);
                
                    student.serial = new Number(g.start_serial_all_check ? g.start_serial_all : g.start_serial[student.classname]);
                g.start_serial[student.classname] = new Number(g.start_serial[student.classname]) + 1;
                g.start_serial_all = new Number(g.start_serial_all) + 1;
                c.students.push(student);
                stus.push(student);
              }
            });
          
          }
        });
      }
      if (g.orderedType == 3 || g.orderedType == 2) {
        g.save();
      }
    },
    save() {
      var g = this;
      g.saved = true;
      $.post(api + "/committees/save", {
        jwt: user.jwt,
        arr: JSON.stringify(g.committees),
        type: g.orderedType,
      })
        .then(function () {
          // if (g.orderedType == 3 || g.orderedType == 2) {
          $.post(api + "/committees/history", {
            jwt: user.jwt,
          })
            .then(function (a) {
              window.location = "/create#" + a.response[0]._id;
              location.reload();
            })
            .catch(function (a) {
              alert("حدث خطأ");
              g.loading = false;
            });
          //  }
        })
        .catch(function (a) {
          alert("حدث خطأ في الاتصال");
        });
    },
    teachersfetch(){
      var g = this;
      g.committees.forEach(function(ac){
        g.teachersC[ac.title] = "select";
        g.manuals[ac.title] = false;
      })
      g.teachersC['e1'] = "select";
      g.manuals['e1'] = false;
      g.teachersC['e2'] = "select";
      g.manuals['e2'] = false;
      g.teachersC['m1'] = "select";
      g.manuals['m1'] = false;
      g.teachersC['m2'] = "select";
      g.manuals['m2'] = false;
      g.teachersC['m3'] = "select";
      g.manuals['m3'] = false;
      g.teachersC['mor'] = "";
      g.teachersC['mod'] = "";
      g.teachersC['day'] = "";
      g.teachersC['date'] = "";
      g.teachersC['fatra'] = "";
      g.teachersC['subject'] = "";
      g.teachersC['notes'] = `الحضور للمدرسة الساعة ٧ ويبدأ الاختبار الساعة ٧:٠٠ صباحاً
يجب ابلاغ الوكيل عن الطالب المتغيب بعد مضي ١٠ دقائق من بداية الاختبار
يجب تنبيه الطلاب بالذهاب لدورة المياه قبل بداية الاختبار ويمنع الخروج اثناء الاختبار
يجب التأكد من عدد الأوراق قبل تسليمها وترتيبها حسب الكشف
عدم استلام الورقة من الطالب الا بعد التأكد من إجابة الطالب على جميع الأسئلة
التواصل مع الموجه الطلابي مباشرة في حال وجود ملاحظات صحية على الطالب`;
      g.refreshTeachers()
    },
    printc(){
var g = this;
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write(
        '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous"><style type = "text/css">'
      );
      var coms = ""
      g.committees.forEach(function(a){
        coms = coms + `
        <tr>
          <td>
            ${a.title}
          </td>
          <td>
            ${a.address}
          </td>
          <td style='padding-right: 0px; padding-left: 0px;'>
            ${g.teachersC[a.title].replace('select', "").split("\n").join("<br><div style='width: 100%; border-top: solid 1px #555;'><span class='g'></span></div>")}
          </td>
          <td style='padding-right: 0px; padding-left: 0px;'>
            ${g.teachersC[a.title].replace('select', "").split("\n").map(function(x){return ""}).join("<br><div style='width: 100%; border-top: solid 1px #555'></div>")}
          </td>
        </tr>
        `
      })
      printWindow.document.write(`
      [dir] .table-bordered th, [dir] .table-bordered td{
    border: 1px solid #000 !important;
}
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
              text-align: right;
          }
          table, td, th {
  border: 1px solid #000 !important;
}

table {
  width: 100%;
  border-collapse: collapse;
}

         @media print{
          table, td, th {
  border: 1px solid #000 !important;
}
.table-bordered th, .table-bordered td{
    border: 1px solid #000 !important;
}
        }
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
          <div class='container-fluid'>
            <div class='row'>
            
        <div style='' class='col-4'>
          <h3>
            المملكة العربية السعودية
            <br>
            ${info["edara"] ?  info["edara"] + "<br>"  : ""} 
            ${info["maktab"] ? info["maktab"] : ""} <br>${info["school"] ? info["school"] : user.name}
          </h5>
        </div>
        <div  class='col-4'>
        <img style="width: 150px;" src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg">
          <h4>
          جدول توزيع الملاحظين على اللجان
          </h4>
        </div>
        <div  class='col-4'>
          <br>
          <h4>
            1446هـ
            /
            1445
            <br>
            الفصل الدراسي: الثاني
          </h4>
        </div>
            <div class='col-12'>
            <table class='table table-smc table-bordered'>
              <tbody>
                <tr>
                  <td>
                    اليوم
                  </td>
                  <td>
                    ${g.teachersC['day']}
                  </td>
                  <td>
                    التاريخ
                  </td>
                  <td>
                    ${g.teachersC['date']}
                  </td>
                </tr>
                <tr>
                  <td>
                    المادة
                  </td>
                  <td>
                    ${g.teachersC['subject']}
                  </td>
                  <td>
                    الفترة
                  </td>
                  <td>
                    ${g.teachersC['fatra']}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class='table table-smc table-bordered'>
              <thead>
                <th>
                  اللجنة
                </th>
                <th>
                  مقرها
                </th>
                <th>
                  اسم الملاحظ
                </th>
                <th>
                  التوقيع
                </th>
              </thead>
              <tbody>
                ${coms}
              </tbody>
            </table>
            <h5>
              الاحتياط
            </h5>
            <table class='table table-smc table-bordered'>
              <thead>
                <th>
                  الاحتياط الاول
                </th>
                <th>
                  التوقيع
                </th>
                <th>
                  الاحتياط الثاني
                </th>
                <th>
                  التوقيع
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    ${this.teachersC['e1'].replace("select", "")}
                  </td>
                  <td>
                  
                  </td>
                  <td>
                    ${this.teachersC['e2'].replace("select", "")}
                  </td>
                  <td>
                  
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>
              الاشراف على الطالب قبل دخول اللجنة وبعد الانصراف
            </h5>
            <table class='table table-smc table-bordered'>
              <thead>
                <th>
                  المشرف
                </th>
                <th>
                  التوقيع
                </th>
                <th>
                  المشرف
                </th>
                <th>
                  التوقيع
                </th>
                <th>
                  المشرف
                </th>
                <th>
                  التوقيع
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    ${this.teachersC['m1'].replace("select", "")}
                  </td>
                  <td>
                  
                  </td>
                  <td>
                    ${this.teachersC['m2'].replace("select", "")}
                  </td>
                  <td>
                  
                  </td>
                  <td>
                    ${this.teachersC['m3'].replace("select", "")}
                  </td>
                  <td>
                  
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <h5>
              تنبيهات هامة:
              <br>
              - ${this.teachersC['notes'].replaceAll("\n", "<br> - ")}
              </h5>
            </p>
            <div class='row'>
              <div class='col-6 text-center'>
                <h5>
                  مراقب اللجان
                </h5>
                <h5>
                  ${this.teachersC['mor'].replace("select", "")}
                </h5>
              </div>
              <div class='col-6 text-center'>
                <h5>
                  مدير المدرسة
                </h5>
                <h5>
                  ${this.teachersC['mod'].replace("select", "")}
                </h5>
              </div>
            </div>
            </div>
            </div>
            </div>
            </body></html>
            `);
printWindow.document.close()
      setTimeout(() => {
      printWindow.print();
      }, 2000);
    },
    printClass(classname) {
      var g = this;
      var printWindow = window.open("", "");
      printWindow.document.write(`<html><head dir='rtl'><title>tahdir.net</title>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"><\/script> <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"><\/script> <script src="https://www.jqueryscript.net/demo/Export-Html-Table-To-Excel-Spreadsheet-using-jQuery-table2excel/src/jquery.table2excel.js"><\/script>
        `
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          .exportExcel{
            position: absolute;
            top: 80px;
            left: 0px;
            z-index: 9999 !important;
        }
         @media print{
          .exportExcel{
              display:none
          }
        }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              padding: 5px;
              border: 1px solid #ccc;
          }
          .no, svg{
            display: none !important;
          }
          tbody td{
            white-space: nowrap;
          }
          
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%; display: table-header-group;'>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            ${info["edara"] ?  info["edara"] + "<br>"  : ""} 
            ${info["maktab"] ? info["maktab"] : ""} <br>${info["school"] ? info["school"] : user.name}
          </h5>
        </div>
        <div class="exportExcel" onclick='
    $(".info").each(function(){
        if(!$(this).is(":visible")){
            $(this).remove()
        } 
    })
    $("#ff").table2excel({
            
            exclude:".noExl",
        
            name:"Worksheet Name",
        
            filename:"SomeFile",//do not include extension
        
            fileext:".xls" // file extension
        
          });
          setTimeout(() => {
            window.close()
          }, 3000);
        '>
        <button class="btn btn-success" style='color: white; padding: 10px; background: green' style="border-radius: 0px">تصدير لـ Excel</button>
    </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
        <img style="width: 150px;" src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg">
          <h4>كشف توزيع الطلاب على اللجان حسب الصف</h4>
          <h4>
            1446هـ
            /
            1445
            <br>
            الفصل الدراسي: الثاني
          </h4>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          الصف: ${g.classname(classname)}
          </h3>
        </div>
        <hr>
      </div>
      `);
      var tabletext = "",
        i = 0;
        g.committees.forEach(function(a){
          a.students.forEach(function (student) {
            if(student.classname == classname){
              i = i + 1;
            tabletext =
              tabletext +
              `
            <tr>
              <td>${i}</td>
              <td>${student.serial ?? ""}</td>
              <td>${student.number}</td>
              <td>${student.name}</td>
              <td>${a.title}</td>
              <td>${g.classname(student.classname)}</td>
              <td>${student.classroom}</td>
            </tr>
            `;
            }
          });
        })
      printWindow.document.write(
        `<table id='ff' style='display: table-row-group; width: 100%'>
      <thead>
        <th>م</th>
        <th>رقم الجلوس</th>
        <th>رقم الهوية</th>
        <th>اسم الطالب</th>
        <th>اللجنة</th>
        <th>الصف</th>
        <th>الفصل</th>
      </thead>` +
          tabletext +
          "</table>"
      );
      printWindow.document.write(
        "<br><center></center></body></html>"
      );
      printWindow.document.close()
      setTimeout(() => {
        printWindow.print();
      }, 2000);
    },
    refreshTeachers(){
      var g = this;
      g.dones = [];
      this.teachersC = JSON.parse(JSON.stringify(this.teachersC))
      Object.entries(this.teachersC).forEach(([key, value]) => {
        g.dones.push(value)
      });
    },
    print1(c) {
      var g = this;
      var printWindow = window.open("", "");
      printWindow.document.write(`<html><head dir='rtl'><title>tahdir.net</title>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"><\/script> <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"><\/script> <script src="https://www.jqueryscript.net/demo/Export-Html-Table-To-Excel-Spreadsheet-using-jQuery-table2excel/src/jquery.table2excel.js"><\/script>
        `
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          .exportExcel{
            position: absolute;
            top: 80px;
            left: 0px;
            z-index: 9999 !important;
        }
         @media print{
          .exportExcel{
              display:none
          }
        }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              padding: 5px;
              border: 1px solid #ccc;
          }
          .no, svg{
            display: none !important;
          }
          tbody td{
            white-space: nowrap;
          }
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%;'>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            ${info["edara"] ?  info["edara"] + "<br>"  : ""} 
            ${info["maktab"] ? info["maktab"] : ""} <br>${info["school"] ? info["school"] : user.name}
          </h5>
        </div>
        <div class="exportExcel" onclick='
    $(".info").each(function(){
        if(!$(this).is(":visible")){
            $(this).remove()
        } 
    })
    $("#ff").table2excel({
            
            exclude:".noExl",
        
            name:"Worksheet Name",
        
            filename:"SomeFile",//do not include extension
        
            fileext:".xls" // file extension
        
          });
          setTimeout(() => {
            window.close()
          }, 3000);
        '>
        <button class="btn btn-success" style='color: white; padding: 10px; background: green' style="border-radius: 0px">تصدير لـ Excel</button>
    </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
        <img style="width: 150px;" src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg">
          <h4>كشف توزيع الطلاب على اللجان حسب اللجنة</h4>
          <h4>
            1446هـ
            /
            1445
            <br>
            الفصل الدراسي: الثاني
          </h4>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          اللجنة: ${c.title}
          <br><br>
          مقر اللجنة: ${c.address}
          </h3>
        </div>
        <hr>
      </div>
      `);
      var tabletext = "",
        i = 0;
      c.students.forEach(function (student) {
        i = i + 1;
        tabletext =
          tabletext +
          `
        <tr>
          <td>${i}</td>
          <td>${student.serial ?? ""}</td>
          <td>${student.number}</td>
          <td>${student.name}</td>
          <td>${g.classname(student.classname)}</td>
          <td>${student.classroom}</td>
        </tr>
        `;
      });
      printWindow.document.write(
        `<table id='ff'>
      <thead>
        <th>م</th>
        <th>رقم الجلوس</th>
        <th>رقم الهوية</th>
        <th>اسم الطالب</th>
        <th>الصف</th>
        <th>الفصل</th>
      </thead>` +
          tabletext +
          "</table>"
      );
      printWindow.document.write(
        "<br><center></center></body>"
      );
      printWindow.document.close()
      setTimeout(() => {
        printWindow.print();
      }, 2000);
    },
    print2(c) {
      var g = this;
      var printWindow = window.open("", "");
      
      printWindow.document.write(`<html><head dir='rtl'><title>tahdir.net</title>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"><\/script> <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"><\/script> <script src="https://www.jqueryscript.net/demo/Export-Html-Table-To-Excel-Spreadsheet-using-jQuery-table2excel/src/jquery.table2excel.js"><\/script>
        `
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          .exportExcel{
            position: absolute;
            top: 80px;
            left: 0px;
            z-index: 9999 !important;
        }
         @media print{
          .exportExcel{
              display:none
          }
        }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              padding: 0px;
              border: 1px solid #ccc;
          }
          .no, svg{
            display: none !important;
          }
          tbody td{
            white-space: nowrap;
          }
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%; '>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            
            ${info["edara"] ?  info["edara"] + "<br>"  : ""} 
            ${info["maktab"] ? info["maktab"] : ""} <br>${info["school"] ? info["school"] : user.name}
          </h5>
        </div>
        <div class="exportExcel" onclick='
    $(".info").each(function(){
        if(!$(this).is(":visible")){
            $(this).remove()
        } 
    })
    $("#ff").table2excel({
            
            exclude:".noExl",
        
            name:"Worksheet Name",
        
            filename:"SomeFile",//do not include extension
        
            fileext:".xls" // file extension
        
          });
          setTimeout(() => {
            window.close()
          }, 3000);
        '>
        <button class="btn btn-success" style='color: white; padding: 10px; background: green' style="border-radius: 0px">تصدير لـ Excel</button>
    </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
        <img style="width: 100px;" src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg">
          <h4>كشف استلام اوراق اجابات الطلاب</h4>
          <h4>
            1446هـ
            /
            1445
            <br>
            الفصل الدراسي: الثاني
          </h4>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          اللجنة: ${c.title}
          <br><br>
          مقر اللجنة: ${c.address}
          </h3>
        </div>
        <div style='width:100%'>
          <table>
            <thead>
              <th>
              المادة
              </th>
              <th>
                <input style='width: 100%; height: 20px;'>
              </th>
              <th>
              اليوم
              </th>
              <th>
                <input style='width: 100%; height: 20px;'>
              </th>
              <th>
              الفترة
              </th>
              <th>
                <input style='width: 100%; height: 20px;'>
              </th>
              <th>
              التاريخ
              </th>
              <th>
                <input style='width: 100%; height: 20px;' value="         /         /">
              </th>
            </thead>
          </table>
        </div>
        <hr>
      </div>
      `);
      var tabletext = "",
        i = 0;
      c.students.forEach(function (student) {
        if(!g.selectedclass || g.selectedclass == student.classname || g.size2 == "all"){
          i = i + 1;
          tabletext =
            tabletext +
            `
          <tr>
            <td>${i}</td>
            <td style='text-align: center;'>${student.serial ?? ""}</td>
            <td>${student.number}</td>
            <td>${student.name}</td>
            <td>${g.classname(student.classname)}</td>
            <td style='width: 80px'></td>
            <td style='width: 80px'></td>
          </tr>
          `;
        }
      });
      printWindow.document.write(
        `<table  id='ff'>
      <thead>
        <th>م</th>
        <th>رقم الجلوس</th>
        <th>رقم الهوية</th>
        <th>اسم الطالب</th>
        <th>الصف</th>
        <th>زمن الخروج</th>
        <th>التوقيع</th>
      </thead>` +
          tabletext +
          "</table>"
      );
      printWindow.document.write(
        `
        <br>
        <div style='width:100%; break-inside: avoid;'>
          <table>
            <thead>
              <th>
                عدد الطلاب
              </th>
              <th>
                الملاحظون
              </th>
              <th>
                المراقب
              </th>
              <th>
                الكنترول
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  حاضرون:
                  <hr>
                  غائبون:
                  <hr>
                  المجموع:
                </td>
                <td>
                  الاسم:
                  <hr>
                  التوقيع:
                  <hr>
                  الاسم:
                  <hr>
                  التوقيع:
                </td>
                <td>
                  الاسم:
                  <hr>
                  وقت الاستلام:
                  <hr>
                  التوقيع:
                </td>
                <td>
                  الاسم:
                  <hr>
                  وقت الاستلام:
                  <hr>
                  التوقيع:
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr>
      </div>
        <br><center></center></body>`
      );
      printWindow.document.close()
      setTimeout(() => {
        printWindow.print();
      }, 2000);
    },
    print4(c) {
      var g = this;
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              padding: 5px;
              border: 1px solid #ccc;
          }
          .no, svg{
            display: none !important;
          }
          tbody td{
            white-space: nowrap;
          }
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      var studentslist = "";
      var classes = [];
      c.students.forEach(function (a) {
        if (!classes.includes(g.classname(a.classname))) {
          classes.push(g.classname(a.classname));
        }
      });
      classes.forEach((element) => {
        var count = 0;
        c.students.forEach(function (a) {
          if (g.classname(a.classname) == element) {
            count = count + 1;
          }
        });
        studentslist =
          studentslist +
          `
        <tr>
          <td>
            ${element}
          </td>
          <td>
            ${count}
          </td>
        </tr>`;
      });
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%; '>
        <div style='width: 45%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            ${info["edara"] ?  info["edara"] + "<br>"  : ""} 
            ${info["maktab"] ? info["maktab"] : ""} <br>${info["school"] ? info["school"] : user.name}
          </h5>
        </div>
        <div style='width: 45%; text-align: center; display: inline-block'>
        <img style="width: 150px;" src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg">
          <h4>
          العام الدراسي: 
            1446هـ
            /
            1445
            <br>
            الفصل الدراسي: الثاني
          </h4>
        </div>
        <div stylw='width: 100%'>
        <div style='width:45%; display: inline-block'>
          <table style='text-align: center'>
            <tbody>
              <tr>
                <td>
                رقم اللجنة
                </td>
                <td>
                ${c.title.split(" ")[c.title.split(" ").length - 1]}
                </td>
              </tr>
              <tr>
                <td>
                مقر اللجنة
                </td>
                <td>
                ${c.address}
                </td>
              </tr>
              <tr>
                <td>
                اسم اللجنة
                </td>
                <td>
                ${c.title}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style='width:45%;  display: inline-block'>
          <table style='text-align: center'>
          <thead>
            <th>
              المرحلة
            </th>
            <th>
              عدد الطلاب
            </th>
          </thead>
            <tbody>
              ${studentslist}
            </tbody>
          </table>
        </div></div>
      </div>
      `);
      printWindow.document.write(
        `
        <hr>
        <br><center></center></body>`
      );
      printWindow.document.close()
      setTimeout(() => {
        printWindow.print();
      }, 2000);
    },
    print3(c) {
      var g = this;
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write(
        '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous"><style type = "text/css">'
      );
      printWindow.document.write(`
      [dir] .table-bordered th, [dir] .table-bordered td{
    border: 1px solid #000 !important;
}
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
              text-align: right;
          }
          @media print {
              body{
                .col-6:nth-child(1),
                .col-6:nth-child(2),
                .col-6:nth-child(3),
                .col-6:nth-child(4){
                  position: relative;
                  top: 20px;
                }
              }
            }
            /*  ${g.size == 'col-4' ? 'body,html{ width: 21cm; height: 29.7cm; }' : 'body,html{ width: 100%; }'} */
            body,html{ width: 100%; }
          
          td,tr{
            padding: 1px !important;
          }
          /*.${g.size}{
            height: 14.2857143vh;
          }*/
          ${g.size == 'col-4' ? '.col-4{ height: 14.2857143vh; padding: 10px; /*width: 7cm; height: 5cm*/ }' : '.col-6{height: 14.2857143vh; padding: 10px;}'}
          ${g.size4 == '6' ? '.col-4,.col-6{height: 16.6666667vh}' : ''} 
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
          <div class='container-fluid'>
            <div class='row'>`);
      var tabletext = "", i=0;
      c.students.forEach(function (student) {
        i++
        tabletext =
          tabletext +
          `
              <div class='${g.size}'>
            <div class='card card-body' style='height: 100%; padding: 5px;  border: 2px dashed #222'>
            <small class='text-center'>${info["school"] ? info["school"] : user.name} / اختبارات الفصل: الثاني
            1446هـ
            /
            1445
            </small>
            <table class='table table-bordered'>
              <tbody>
                <tr>
                  <td>
                    رقم الجلوس
                  </td>
                  <td>
                    ${student.serial}
                  </td>
                </tr>
                <tr>
                  <td>
                    الإسم
                  </td>
                  <td>
                    <strong style='font-size: 12pt !important'>${
                      student.name
                    }</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    رقم الهوية
                  </td>
                  <td>
                    ${student.number}
                  </td>
                </tr>
                <tr>
                  <td>
                    اللجنة
                  </td>
                  <td>
                    ${c.title}
                  </td>
                </tr>
                <tr>
                  <td>
                    مقر اللجنة
                  </td>
                  <td>
                    ${c.address}
                  </td>
                </tr>
                <tr>
                  <td>
                    الصف والفصل
                  </td>
                  <td>
                    ${g.classname(student.classname)}
                    -
                    الفصل ${student.classroom}
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        `;
        if(i%(3*7) == 0 && g.size == "col-4"){
          tabletext = tabletext + `<div style="clear:both!important;"/></div>
<div style="page-break-after:always"></div> 
<div style="clear:both!important;"/> </div>`
        }
      });
      printWindow.document.write(
        `
            ` +
          tabletext +
          "</div></div><br><center></center></body>"
      );
printWindow.document.close()
      setTimeout(() => {
      printWindow.print();
      }, 2000);
    },
    totalWritedStudents() {
      var total = 0;
      this.committees.forEach(function (a) {
        total = total + new Number(a.count);
      });
      return total;
    },
    countSelectedClassesCount(c) {
      var total = 0;
      for (const cl in c.classes) {
        total = total + new Number(c.classes[cl]);
      }
      return total;
    },
    showMessage(a) {
      alert(a);
    },
    totalStats() {
      var html = ``,
        g = this;
      this.classes.forEach(function (cl) {
        //
        var total_students = 0,
          done = 0;
        g.students.forEach(function (student) {
          if (student.classname == cl.classname) {
            total_students = total_students + 1;
          }
        });
        //
        g.committees.forEach(function (c) {
          done = done + new Number(c.classes[cl.classname]);
        });
        //
        var color = "text-success";
        if (done > total_students) {
          color = "text-danger";
        }
        if (done 
        <
        
        total_students) {
          color = "text-primary";
        }
        html =
          html +
          `
        <li>
          <h5 class='${color}'>
          ${g.classname(cl.classname)}: 
            تم توزيع ${done} من ${total_students} ${
            color != "text-success"
              ? `<span class='text-muted'><i>(متبقي ${
                  total_students - done > 0 ? total_students - done : "---"
                } لم يتم توزيعهم بعد)</i></span>`
              : ""
          }
          </h5>
        </li>
        `;
      });
      return `<ul>${html}</ul>`;
    },
    sendSMS() {
      var g = this;
      if (
        confirm(
          "متأكد من انك قمت بضبط اعدادات الارسال في حسابك في التحضير الذكي؟"
        )
      ) {
        if (
          confirm("متأكد من ارسال " + g.current.students.length + " رسالة؟")
        ) {
          var a = [];
          g.loading = true;
          $("#brmja-messages-table tbody tr").each(function () {
            a.push({
              phone: $(this).find("td:nth-child(1)").text().trim(),
              message: $(this).find("textarea").val().trim(),
            });
          });
          $.post(api2 + "/user/send-with-jwt", {
            jwt: user.jwt,
            arr: JSON.stringify(a),
          })
            .then(function () {
              g.loading = false;
              alert(
                "تم الارسال بنجاح، برجاء فتح ارشيف الرسائل في حسابك للتأكد من ارسال الرسائل."
              );
            })
            .catch(function () {
              alert(
                "حدث خطأ في الارسال\n برجاء التأكد من اتصالك بالانترنت او تواصل مع الدعم الفني."
              );
            });
        }
      }
    },
    moveStudent(id) {
        var title = this.movetott;
        var newarr = [],
          newarr2 = [],
          newarr3 = [],
          student = {},
          minserial = {},
          g = this;
        g.committees.forEach(function (a) {
          var st = [];
          a.students.forEach(function (stu) {
            if (!minserial[stu.classname]) {
              minserial[stu.classname] = stu.serial;
            }
            if (stu._id != id) {
              st.push(stu);
            } else {
              student = stu;
            }
          });
          a.students = st;
          newarr.push(a);
        });
        newarr.forEach(function (a) {
          if (a.title == title) {
            a.students.push(student);
          }
          newarr2.push(a);
        });
        newarr2.forEach(function (a) {
          a.students.forEach((element) => {
            element.serial = minserial[element.classname];
            minserial[element.classname] = minserial[element.classname] + 1;
          });
          newarr3.push(a);
        });
        g.committees = newarr3;

      setTimeout(() => {
        $.post(api + "/committees/update", {
        jwt: user.jwt,
        id: window.location.hash.split("#")[1],
        arr: JSON.stringify(newarr3),
      })
        .then(function (a) {})
        .catch(function () {
          alert("حدث خطأ");
        });
      }, 1000);
    },
    moveUp(title, index, number) {
      var newarr = [],
        newarr2 = [],
        newarr3 = [],
        student = {},
        minserial = {},
        g = this;
      g.committees.forEach(function (a) {
         
         a.students.forEach(function (stu) {
            if (!minserial[stu.classname]) {
              minserial[stu.classname] = stu.serial;
            }
          });
        if(a.title == title){
          var temp = a.students[index];
          a.students[index] = a.students[index - 1];
          a.students[index - 1] = temp;
        }
        a.students.forEach((element) => {
          element.serial = minserial[element.classname];
          minserial[element.classname] = minserial[element.classname] + 1;
        });
        newarr3.push(a);
      });
        g.committees = newarr3;
        $("#f" + number).fadeOut(0);
        $("#f" + number).fadeIn('slow');
      setTimeout(() => {
        $.post(api + "/committees/update", {
        jwt: user.jwt,
        id: window.location.hash.split("#")[1],
        arr: JSON.stringify(newarr3),
      })
        .then(function (a) {})
        .catch(function () {
          alert("حدث خطأ");
        });
      }, 1000);
    },
    moveDown(title, index, number) {
      var newarr = [],
        newarr2 = [],
        newarr3 = [],
        student = {},
        minserial = {},
        g = this;
      g.committees.forEach(function (a) {
         a.students.forEach(function (stu) {
            if (!minserial[stu.classname]) {
              minserial[stu.classname] = stu.serial;
            }
          });
        if(a.title == title){
          var temp = a.students[index];
          a.students[index] = a.students[index + 1];
          a.students[index + 1] = temp;
        }
        a.students.forEach((element) => {
          element.serial = minserial[element.classname];
          minserial[element.classname] = minserial[element.classname] + 1;
        });
        newarr3.push(a);
      });
      g.committees = newarr3;
        $("#f" + number).fadeOut(0);
        $("#f" + number).fadeIn('slow');
      setTimeout(() => {
        $.post(api + "/committees/update", {
        jwt: user.jwt,
        id: window.location.hash.split("#")[1],
        arr: JSON.stringify(newarr3),
      })
        .then(function (a) {})
        .catch(function () {
          alert("حدث خطأ");
        });
      }, 1000);
    },
    changeAttr(c, att) {
      var t = prompt("", c[att]);
      if (t != null && t) {
        c[att] = t;
        $.post(api + "/committees/update", {
          jwt: user.jwt,
          id: window.location.hash.split("#")[1],
          arr: JSON.stringify(this.committees),
        })
          .then(function (a) {})
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
  },
};
</script>

<style>
.hover .card:hover {
  background: lightcyan;
  border: solid 2px lightseagreen !important;
  cursor: pointer;
}
.selectedCard .card {
  background: lightcyan;
  border: solid 2px lightseagreen !important;
}
[dir] .mb-3,
[dir] .my-3 {
  margin-bottom: 0px !important;
}
.text-white2 strong {
  color: #fff !important;
}
.dropdown-toggle.btn-secondary{
  padding: 0px;
  border-radius: 0px;
  padding: 0.466rem;
}
#cccsssxsx *{
  white-space: nowrap !important;
}
#cccsssxsx select, #cccsssxsx input{
  min-width: 80px !important;
}
</style>